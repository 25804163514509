.title {
  color: #333 !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.start {
  margin-left: 5px;
  color: #555;
}

.buttonModal {
  background: #29be19 !important;
  border-color: #29be19 !important;
  display: flex !important;
  align-items: center;
  gap: 6px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttonModal b {
  color: #fff;
}

.buttonDelete {
  background: #ff0000 !important;
  border-color: #ff0000 !important;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.buttonDelete b {
  color: #fff;
}

.buttonInfo {
  background: #339fcc !important;
  border-color: #339fcc !important;
  display: flex !important;
  align-items: center;
  gap: 6px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttonInfo b {
  color: #fff;
}

.buttonContainer {
  display: flex;
  gap: 10px;
}
