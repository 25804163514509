.logo {
  max-width: 150px !important;
  height: auto;
}

.logoReact {
  max-width: 70px !important;
  height: auto;
}

.logoReactTools {
  max-width: 80px !important;
  height: auto;
  margin-left: 10px;
}

.footer {
  width: 100%;
  background-color: #555;
  text-align: center;
  padding: 8px 0;
}
.footer a {
  color: white;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 20px 0 20px 0;
  justify-content: start;
}

.github {
  max-width: 140px !important;
  height: auto;
}

.title {
  font-size: 22px;
  font-weight: bold;
}

.header {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.23);
  margin-bottom: 30px;
  background-color: #fff;
}

.buttonModal {
  background: #29be19 !important;
  border-color: #29be19 !important;
  display: flex !important;
  align-items: center;
  gap: 6px;
}

.buttonModal b {
  color: #fff;
}
