body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-wrapper {
  padding: 120px;
  padding-top: 0px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .main-wrapper {
    padding: 20px;
  }
}

.fc td {
  background: #fff;
}

.fc th {
  background: #339fcc;
  color: #fff;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-event-main-frame {
  flex-direction: row;
  text-align: left;
}

.fc-event-desc,
.fc-event-title {
  white-space: break-spaces;
}

.fc-daygrid-day-top a {
  background-color: #f1f1f1;
  width: 30px;
  text-align: center;
  font-weight: bold;
  min-height: 20px;
}

.fc-v-event {
  background: none;
  border: none;
  width: fit-content;
}
.fc-v-event:hover {
  background: #f1f1f1;
  border: none;
}
